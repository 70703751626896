import Polyfills from "./general/polyfills";
import MultipleInstances from "./general/multipleInstances";
import Header from "./header/header";
import MainMenu from "./main-menu/main-menu";
import PhotoDetails from "./photo-details/photo-details";
import General from "./general/general";
import BigImageVideo from "./big-image-swiper/big-image-video";
import ArchivePage from "./archive-page/archive-page";
import OnScreenElements from "./on-screen-elements/on-screen-elements";
import TeaserWidget from "./teaser-widget/teaser-widget";
import ArchiveCategory from "./archive-page/archive-category";
import BackButton from "./back-button/back-button";
import RegionsMenu from "./regions-menu/regions-menu";
import RegionScroll from "./region-scroll/region-scroll";
import SplitText from "./split-text/split-text";
import WinnersNew from "./winners-new/winners-new";
import PhotoDetailsNew from "./photo-details-new/photo-details-new";
import Popup from "./popup-warning/popup-warning";

const modules = () => {
    const general = new General();
    const polyfills = new Polyfills();
    const header = new Header();
    const mainMenu = new MainMenu();
    const multipleInstances = new MultipleInstances();
    const photoDetails = new PhotoDetails();
    const bigImageVideo = new BigImageVideo();
    const archivePage = new ArchivePage();
    const onScreenElements = new OnScreenElements();
    const teaserWidget = new TeaserWidget();
    const archiveCategory = new ArchiveCategory();
    const backButton = new BackButton();
    const regionsMenu = new RegionsMenu();
    const splitText = new SplitText();
    const photoDetailsNew = new PhotoDetailsNew();
    const winnersNew = new WinnersNew();
    const regionScroll = new RegionScroll();
    const initialScrollContainer = $(".region-scroll__not-loaded");
    const popup = new Popup();

    $(() => {
        general.init();
        polyfills.init();
        header.init();
        mainMenu.init();
        multipleInstances.init();
        photoDetails.init();
        bigImageVideo.init();
        archivePage.init();
        onScreenElements.init();
        teaserWidget.init();
        archiveCategory.init();
        backButton.init();
        regionsMenu.init();
        splitText.init();
        photoDetailsNew.init();
        winnersNew.init();
        regionScroll.init();
        popup.init();
    });

    window.addEventListener("load", () => {
        regionScroll.getImgWidthAndHeight();
        regionScroll.setScroll();
        initialScrollContainer.removeClass("region-scroll__not-loaded");
    });
};

export default modules;
