import General from "../general/general";

export default class regionsMenu {
    mainMenu = $(".js-menu");
    mainMenuOpenClass = "main-menu--open";
    regionsMenu = $(".js-regions-menu");
    regionsMenuMobile = $("[data-mobile='true']");
    menuOpenClass = "regions-menu--open";
    mainMenuBtn = $(".js-menu-btn");
    mainMenuBtnOpenClass = "header__menu-btn--open";
    regionsMenuBtn = $(".js-regions-btn");
    search = $(".js-search-element");
    animatableVisibleClass = "animatable--visible";
    animatableHiddenClass = "animatable--hidden";
    animatableStartPositionYTopClass = "animatable--start-y-top";
    animatableStartPositionYBottomClass = "animatable--start-y-bottom";
    animatableEndPositionYClass = "animatable--end-y";
    animatableAbsoluteStartPosititionYTopClass =
        "animatable--start-y-top-absolute-centered";
    animatableAbsoluteEndPosititionYTopClass =
        "animatable--end-y-absolute-centered";
    noScrollClass = "noScroll";
    regionsMenuNavItems = $(".js-regions-menu-list li");
    body = $("body");
    breakpoint = window.matchMedia("(min-width: 767px)");

    init = () => {
        if (!this.regionsMenu.length) return;

        this.openRegionsMenu();

        const openCloseOverviewMenuOnMobile = () => {
            if (this.regionsMenuMobile.length) {
                if (!this.breakpoint.matches) {
                    this.animateWithDelay(
                        this.regionsMenuNavItems,
                        200,
                        125,
                        true
                    );

                    this.body.addClass(this.noScrollClass);
                    this.regionsMenuMobile.addClass(this.menuOpenClass);
                    this.regionsMenuBtn.addClass("active");
                } else {
                    if (this.mainMenu.hasClass(this.mainMenuOpenClass)) {
                        this.mainMenu.removeClass(this.mainMenuOpenClass);
                        this.hideElement(this.search, "up");
                        this.mainMenuBtn.removeClass(this.mainMenuBtnOpenClass);
                    }

                    this.animateWithDelay(
                        this.regionsMenuNavItems,
                        200,
                        50,
                        false
                    );

                    this.body.removeClass(this.noScrollClass);
                    this.regionsMenuMobile.removeClass(this.menuOpenClass);
                    this.regionsMenuBtn.removeClass("active");
                }
            }
        };

        openCloseOverviewMenuOnMobile();

        window.addEventListener("resize", function () {
            openCloseOverviewMenuOnMobile();
        });
    };

    showElement = (el, direction) => {
        el.removeClass(this.animatableHiddenClass);

        if (direction === "up") {
            el.removeClass(this.animatableStartPositionYBottomClass);
        } else {
            el.removeClass(this.animatableStartPositionYTopClass);
        }

        el.addClass(this.animatableVisibleClass).addClass(
            this.animatableEndPositionYClass
        );
    };

    hideElement = (el, direction) => {
        el.removeClass(this.animatableVisibleClass)
            .removeClass(this.animatableEndPositionYClass)
            .addClass(this.animatableHiddenClass);

        if (direction === "up") {
            el.addClass(this.animatableStartPositionYTopClass);
        } else {
            el.addClass(this.animatableStartPositionYBottomClass);
        }
    };

    animateWithDelay = (items, duration, delay, shouldShow) => {
        const totalItems = items.length - 1;

        if (shouldShow) {
            items.each((i, item) => {
                const $item = $(item);

                if ($item.css("display") !== "none") {
                    setTimeout(() => {
                        this.showElement($item, "up");
                    }, duration);

                    duration += delay;
                }
            });
        } else {
            items.each((i, item) => {
                const $item = $(item);

                if ($item.css("display") !== "none") {
                    setTimeout(() => {
                        this.hideElement($item);

                        if (i === totalItems) {
                            this.regionsMenu.removeClass(this.menuOpenClass);
                        }
                    }, duration);

                    duration += delay;
                }
            });
        }
    };

    openRegionsMenu = () => {
        this.regionsMenuBtn.click(() => {
            if (this.mainMenu.hasClass(this.mainMenuOpenClass)) {
                this.mainMenu.removeClass(this.mainMenuOpenClass);
                this.hideElement(this.search, "up");
                this.mainMenuBtn.removeClass(this.mainMenuBtnOpenClass);
            }

            if (this.regionsMenu.hasClass(this.menuOpenClass)) {
                this.animateWithDelay(this.regionsMenuNavItems, 200, 50, false);
                this.body.removeClass(this.noScrollClass);
            } else {
                this.animateWithDelay(this.regionsMenuNavItems, 200, 50, true);
                this.body.addClass(this.noScrollClass);
            }

            this.regionsMenu.toggleClass(this.menuOpenClass);
            this.regionsMenuBtn.toggleClass("active");

            if (this.breakpoint.matches) {
                this.clickOutsideMenu(this.regionsMenu);
            }
        });
    };

    clickOutsideMenu = (menu, callback) => {
        $(document).on("mousedown", (e) => {
            if (!e.target.closest(".regions-menu > *, .regions__wrapper > *")) {
                this.closeMenu(menu, callback);
            }
        });
    };

    closeMenu = (menu, callback) => {
        menu.removeClass("regions-menu--open");
        this.regionsMenuBtn.removeClass("active");
        this.animateWithDelay(this.regionsMenuNavItems, 200, 50, false);
        this.body.removeClass(this.noScrollClass);

        if (callback) callback();
    };
}
