import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export default class WinnersNew {
    prlx = document.querySelectorAll(".js-paralax");

    init = () => {
        if (!this.prlx.length) return;

        const breakpoint = window.matchMedia("(min-width: 767px)");
        let tl;

        const paralax = () => {
            gsap.utils.toArray(this.prlx).forEach((paralax, i) => {
                tl = gsap.timeline({
                    scrollTrigger: {
                        trigger: paralax,
                        start: "top bottom",
                        end: "bottom top",
                        scrub: 0.8,
                    },
                });

                const depth = paralax.dataset.depth;
                const movement = -(paralax.offsetHeight * depth);

                tl.to(paralax, { y: movement, ease: "none" }, 0);
            });
        };

        if (breakpoint.matches) {
            paralax();
        } else {
            let allTriggers = ScrollTrigger.getAll();

            for (let i = 0; i < allTriggers.length; i++) {
                allTriggers[i].kill(true);
            }

            gsap.set(".js-paralax", { clearProps: true });
        }

        window.addEventListener("resize", function () {
            if (breakpoint.matches) {
                paralax();
            } else {
                let allTriggers = ScrollTrigger.getAll();

                for (let i = 0; i < allTriggers.length; i++) {
                    allTriggers[i].kill(true);
                }

                gsap.set(".js-paralax", { clearProps: true });
            }
        });
    };
}
