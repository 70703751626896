'use strict';

import jQuery from 'jquery';
window.$ = jQuery;
import 'bootstrap';

import functionsInit from './functions';

class App {
    initializeApp() {
        $(() => {
            functionsInit();
        });
    };
}

const app = new App();
app.initializeApp();
