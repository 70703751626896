import Header from '../header/header';

export default class MainMenu {
    headerInstance = new Header();
    menu = $('.js-menu');
    header = $('.js-header');
    menuNav = $('.js-menu-navigation');
    gridMenu = $('.js-grid-menu');
    openGridMenuBtn = $('.js-open-grid');
    menuBtn = $('.js-menu-btn');
    headerButton = $('.js-header-button');
    logo = $('.js-logo');
    body = $('body');
    menuOpenClass = 'main-menu--open';
    menuBtnGridOpenClass = 'header__menu-btn--open-grid';
    addedOffsetTolerance = 20;
    defaultNavPosition = {
        'transform': 'translateY(0)',
        'opacity': 1
    };

    init = () => {
        this.onMenuScroll();
        this.openGridMenu();
    };

    /*
    * function calculates navigation offset from the top and moves the header up if there's a chance of overflow
    * */
    onMenuScroll = () => {
        this.menu.on('scroll', () => {
            const headerHeight = this.header.outerHeight();
            const navTopOffset = this.menuNav.offset().top;
            const offsetTolerance = headerHeight + this.addedOffsetTolerance;

            if (navTopOffset < offsetTolerance) {
                this.header.css({
                    'transform': 'translateY(' + -headerHeight + 'px)',
                    'opacity': 0
                });
            } else {
                this.header.css(this.defaultNavPosition);
            }
        });
    };

    openGridMenu = () => {
        this.openGridMenuBtn.on('click', (e) => {
            e.preventDefault();
            this.headerInstance.toggleMenuBtn(true);
            this.menuBtn.addClass(this.menuBtnGridOpenClass);
            this.gridMenu.addClass(this.menuOpenClass);
            this.body.addClass('noScroll');
            this.headerButton.removeClass('animatable--visible').addClass('animatable--hidden');
            this.logo.removeClass('animatable--visible').addClass('animatable--hidden');
            window.gridSwiperInstance.update();
        });
    };
};
