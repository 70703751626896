import General from '../general/general';

export default class ArchiveSwiper {
    general = new General();
    swiperOptions = {
        resistanceRatio: 0,
        slidesPerView: 4.55,
        spaceBetween: 20,
        breakpoints: {
            1580: {
                slidesPerView: 3.75
            },
            1279: {
                slidesPerView: 3.5
            },
            1024: {
                slidesPerView: 2.4
            },
            767: {
                slidesPerView: 1.8
            },
            360: {
                slidesPerView: 1.15
            }
        },
        on: {
            init: () => {
                this.general.setTxtBoxWidth();
            },
            resize: () => {
                this.general.setTxtBoxWidth();
            }
        },
        preloadImages: false,
        lazy: true
    };

    swiperInit = (el) => {
        this.swiperOptions.navigation = {
            prevEl: el.find('.js-swiper-btn-prev'),
            nextEl: el.find('.js-swiper-btn-next')
        };
        return this.general.initSwiper(el, this.swiperOptions)
    }
}
