import General from '../general/general';

export default class ThreeColumnsWidgetSwiper {
    general = new General();
    swiperOptions = {
        resistanceRatio: 0,
        slidesPerView: 1.2,
        spaceBetween: 30,
        breakpoints: {
            767: {
                spaceBetween: 20
            }
        }
    };

    swiperInit = (el) => {
        this.swiperOptions.navigation = {
            prevEl: el.find('js-swiper-btn-prev'),
            nextEl: el.find('js-swiper-btn-next')
        };

        return this.general.initSwiper(el, this.swiperOptions)
    }
}
