import General from '../general/general';

export default class PhotoDetails {
    body = $('body');
    noScrollClass = 'noScroll';
    button = $('.js-toggle-content-height');
    buttonArrow = $('.js-toggle-content-height span.button');
    moreInfo = $('.photo-details__section--more-info');
    moreInfoExpandedClass = 'photo-details__section--more-info-expanded';
    toggleArrowUp = 'toggle-arrow-up';
    toggleArrowDown = 'toggle-arrow-down';
    arrowUp = 'button--arrow-down-rotated';
    arrowDown = 'button--arrow-down';
    arrowRight = 'button--arrow-right';
    arrowLeft = 'button--arrow-left';
    mobileButton = $('.js-toggle-mobile-content-position');
    mobileButtonContainer = $('.photo-details__more-info-mobile');
    moreInfoMobileVisibleClass = 'photo-details__section--more-info-mobile-visible';
    mobileButtonCloseInfo = 'photo-details__more-info-mobile--close';
    stickyElement = $('.js-sticky-content');
    stickyElementHolder = $('.js-sticky-content-holder');
    stickyElementClass = 'photo-details__fixed-column-content--sticky';
    stickyElementBottomPositionClass = 'photo-details__fixed-column-content--sticky-bottom';
    general = new General();
    window = $(window);

    init = () => {
        this.toggleHeight();
        this.toggleMobilePosition();
        this.toggleStickyPosition();
    };

    toggleHeight = () => {
        this.button.click((e) => {
            e.preventDefault();
            this.moreInfo.toggleClass(this.moreInfoExpandedClass);
            this.button.toggleClass(this.toggleArrowUp);
            this.button.toggleClass(this.toggleArrowDown);
            this.buttonArrow.toggleClass(this.arrowUp);
            this.buttonArrow.toggleClass(this.arrowDown);
            this.general.smoothScroll(this.button);
            // add offset only while hiding 'More info' section
            if (this.moreInfo.hasClass(this.moreInfoExpandedClass)) {
                this.general.smoothScroll(this.button);
            } else {
                this.general.smoothScroll(this.button, 5);
            }
        });
    };

    toggleMobilePosition = () => {
        this.mobileButton.click((e) => {
            e.preventDefault();
            this.body.toggleClass(this.noScrollClass);
            this.moreInfo.removeClass(this.moreInfoExpandedClass);
            this.moreInfo.toggleClass(this.moreInfoMobileVisibleClass);
            this.mobileButtonContainer.toggleClass(this.mobileButtonCloseInfo);
            this.mobileButton.toggleClass(this.arrowLeft);
            this.mobileButton.toggleClass(this.arrowRight);
        });
    };

    /*
    * - function toggles the fixed position (currently used only for the dark column on the Photo Detail Page)
    * - get the sticky element height -> get the sticky element's parent top position and height -> if parent's element top position is > 0, add fixed position
    *   to sticky element | if parent's element top position is < 0, check if we still have enough space inside the dark section to scroll - if yes,
    *   sticky element gets fixed position and if not, sticky element becomes absolutely positioned on the bottom of the dark section
    * */
    toggleStickyPosition = () => {
        const togglePosition = () => {
            const stickyElementHeight = this.stickyElement.outerHeight();
            const elTopPosition = this.stickyElementHolder[0].getBoundingClientRect().top;
            this.stickyElementHolderHeight = this.general.getElementHeight(this.stickyElementHolder);

            if (elTopPosition > 0) {
                this.stickyElement.removeClass(this.stickyElementClass);
            } else if (elTopPosition < 0) {
                if (this.stickyElementHolderHeight - Math.abs(elTopPosition) > stickyElementHeight) {
                    this.stickyElement.removeClass(this.stickyElementBottomPositionClass).addClass(this.stickyElementClass);
                } else {
                    this.stickyElement.removeClass(this.stickyElementClass).addClass(this.stickyElementBottomPositionClass);
                }
            }
        };

        if (this.stickyElement.length > 0) {
            togglePosition();

            this.window.on('scroll resize', () => {
                togglePosition();
            });
        }
    };
};
