/*
* this class hosts methods to initialize multiple instances of the same item, for example multiple swipers of the same type etc.
* */
import General from '../general/general';
import BigImageSwiper from '../big-image-swiper/big-image-swiper';
import ArticleListSwiper from '../article-list-widget/article-list-widget';
import PhotoCarousel from '../photo-carousel/photo-carousel';
import GridSwiper from '../grid-swiper/grid-swiper';
import PhotoHoverCarousel from '../photo-hover-carousel/photo-hover-carousel';
import CarouselWidgetSwiper from '../carousel-widget/carousel-widget';
import ArchiveSwiper from '../archive-swiper/archive-swiper';
import WinnersGridSwiper from '../winners-grid/winners-grid';
import TwoColumnsWidgetSwiper from '../multicolumn-teaser-article-widget/two-columns-teaser-article-widget';
import ThreeColumnsWidgetSwiper from '../multicolumn-teaser-article-widget/three-columns-teaser-article-widget';
import HighlightedEventWidgetSwiper from '../highlighted-event-widget/highlighted-event-widget';
import BigImageGallerySwiper from '../big-image-gallery/big-image-gallery';

export default class MultipleInstances {
    general = new General();

    init = () => {
        this.bigImageSwipers();
        this.articleListSwiper();
        this.photoCarousel();
        this.gridSwiper();
        this.photoHoverCarousel();
        this.carouselWidgetSwiper();
        this.archiveSwiper();
        this.winnersGridSwiper();
        this.twoColumnsWidgetSwiper();
        this.threeColumnsWidgetSwiper();
        this.highlightedEventWidgetSwiper();
        this.bigImageGallery();
    };

    bigImageSwipers = () => {
        const bigImageSwiper = new BigImageSwiper();
        const bigImageSwiperElements = $('.js-big-image-swiper');

        if (bigImageSwiperElements.length > 0) {
            bigImageSwiperElements.each((i, el) => {
                const $el = $(el);

                bigImageSwiper.swiperInit($el);
            })
        }
    };

    articleListSwiper = () => {
        const articleListSwiper = new ArticleListSwiper();
        const articleListSwiperElements = $('.js-article-list-widget-swiper');

        if (articleListSwiperElements.length > 0) {
            articleListSwiperElements.each((i, el) => {
                const $el = $(el);

                articleListSwiper.swiperInit($el);
            })
        }
    };

    photoCarousel = () => {
        const photoCarousel = new PhotoCarousel();
        const photoCarouselSwiperElements = $('.js-photo-carousel-swiper');

        if (photoCarouselSwiperElements.length > 0) {
            photoCarouselSwiperElements.each((i, el) => {
                const $el = $(el);

                photoCarousel.swiperInit($el);
            })
        }
    };

    gridSwiper = () => {
        const gridSwiper = new GridSwiper();
        const gridSwiperElements = $('.js-grid-swiper');

        if (gridSwiperElements.length > 0) {
            gridSwiperElements.each((i, el) => {
                const $el = $(el);

                gridSwiper.swiperInit($el);
            })
        }
    };

    photoHoverCarousel = () => {
        const photoHoverCarousel = new PhotoHoverCarousel();
        const photoHoverCarouselSwiperElements = $('.js-photo-hover-carousel-swiper');

        if (photoHoverCarouselSwiperElements.length > 0) {
            photoHoverCarouselSwiperElements.each((i, el) => {
                const $el = $(el);

                photoHoverCarousel.swiperInit($el);
            })
        }
    };

    carouselWidgetSwiper = () => {
        const carouselWidgetSwiper = new CarouselWidgetSwiper();
        const carouselWidgetSwiperElements = $('.js-carousel-widget-swiper');

        if (carouselWidgetSwiperElements.length > 0) {
            carouselWidgetSwiperElements.each((i, el) => {
                const $el = $(el);

                carouselWidgetSwiper.swiperInit($el);
            })
        }
    };

    archiveSwiper = () => {
        const archiveSwiper = new ArchiveSwiper();
        const archiveSwiperElements = $('.js-archive-swiper');

        if (archiveSwiperElements.length > 0) {
            archiveSwiperElements.each((i, el) => {
                const $el = $(el);

                archiveSwiper.swiperInit($el);
            })
        }
    };

    winnersGridSwiper = () => {
        const winnersGridSwiper = new WinnersGridSwiper();
        const winnersGridSwiperElements = $('.js-winners-grid-swiper');

        if (winnersGridSwiperElements.length > 0) {
            winnersGridSwiperElements.each((i, el) => {
                const $el = $(el);

                winnersGridSwiper.swiperInit($el);
            })
        }
    };

    twoColumnsWidgetSwiper = () => {
        const twoColumnsWidgetSwiper = new TwoColumnsWidgetSwiper();
        const twoColumnsWidgetSwiperElements = $('.js-two-columns-teaser-article-widget-swiper');

        if (twoColumnsWidgetSwiperElements.length > 0) {
            this.general.toggleSwipersOnMobile(twoColumnsWidgetSwiper, twoColumnsWidgetSwiperElements, 768);
        }
    };

    threeColumnsWidgetSwiper = () => {
        const threeColumnsWidgetSwiper = new ThreeColumnsWidgetSwiper();
        const threeColumnsWidgetSwiperElements = $('.js-three-columns-teaser-article-widget-swiper');

        if (threeColumnsWidgetSwiperElements.length > 0) {
            this.general.toggleSwipersOnMobile(threeColumnsWidgetSwiper, threeColumnsWidgetSwiperElements, 1025);
        }
    };

    highlightedEventWidgetSwiper = () => {
        const highlightedEventWidgetSwiper = new HighlightedEventWidgetSwiper();
        const highlightedEventWidgetSwiperElements = $('.js-highlighted-event-widget-swiper');

        if (highlightedEventWidgetSwiperElements.length > 0) {
            highlightedEventWidgetSwiperElements.each((i, el) => {
                const $el = $(el);

                highlightedEventWidgetSwiper.swiperInit($el);
            })
        }
    };

    bigImageGallery = () => {
        const bigImageGallerySwiper = new BigImageGallerySwiper();
        const bigImageGallerySwiperElements = $('.js-big-image-gallery-swiper');


        if (bigImageGallerySwiperElements.length > 0) {
            bigImageGallerySwiperElements.each((i, el) => {
                const $el = $(el);

                bigImageGallerySwiper.swiperInit($el);
            })
        }
    };
};
