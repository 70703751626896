import General from '../general/general';

export default class PhotoHoverCarousel {
    general = new General();
    swiperOptions = {
        resistanceRatio: 0,
        slidesPerView: 4.1,
        spaceBetween: 20,
        breakpoints: {
            1279: {
                slidesPerView: 3.4
            },
            1024: {
                slidesPerView: 2.4
            },
            767: {
                slidesPerView: 1.02
            }
        },
        on: {
            init: () => {
                this.general.setTxtBoxWidth();
            },
            resize: () => {
                this.general.setTxtBoxWidth();
            }
        },
        preloadImages: false,
        lazy: true
    };

    initHoverEffect = (el) => {
        const items = $(el).find('.swiper-slide');
        const windowSize = window.innerWidth;
        let initialSlideWidth = 0;
        let isInitialSlideWidthSet = false;

        if(windowSize > 1024) {
            items.on('mouseenter', function() {
                const self = $(this);

                // calculating slide width on swiper init returned wrong values, so we calculate the initial slide width only on the first hover over the slide
                if (!isInitialSlideWidthSet) {
                    isInitialSlideWidthSet = true;
                    initialSlideWidth = $('.js-photo-hover-carousel-swiper .swiper-slide').width();
                }

                self.stop().animate({ width: '50%' }, 100 );
                self.parent().parent().swiper[0].update();
            });

            items.on('mouseleave', function() {
                const self = $(this);

                items.stop().animate({ width: initialSlideWidth }, 100);
                self.parent().parent().swiper[0].update();
            });
        } else {
            items.off('mouseenter mouseleave');
        }
    };

    swiperInit = (el) => {
        this.swiperOptions.navigation = {
            prevEl: el.find('.js-swiper-btn-prev'),
            nextEl: el.find('.js-swiper-btn-next')
        };

        this.general.initSwiper(el, this.swiperOptions);

        this.initHoverEffect(el);

        $(window).resize(() => {
            this.initHoverEffect(el)
        });
    }
}
