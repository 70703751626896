import General from '../general/general';

export default class BigImageSwiper {
    general = new General();
    swiperOptions = {
        resistanceRatio: 0
    };

    swiperInit = (el) => {
        this.swiperOptions.navigation = {
            prevEl: el.find('.js-swiper-btn-prev'),
            nextEl: el.find('.js-swiper-btn-next')
        };
        this.general.initSwiper(el, this.swiperOptions)
    };
}
