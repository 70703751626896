import General from "../general/general";

export default class Header {
    menu = $(".js-menu");
    menuOpenClass = "main-menu--open";
    regionsMenu = $(".js-regions-menu");
    regionsMenuBtn = $(".js-regions-btn");
    regionsMenuOpenClass = "regions-menu--open";
    menuBtn = $(".js-menu-btn");
    menuBtnOpenClass = "header__menu-btn--open";
    menuBtnClosedClass = "header__menu-btn--closed";
    search = $(".js-search-element");
    gridMenu = $(".js-grid-menu");
    animatableVisibleClass = "animatable--visible";
    animatableHiddenClass = "animatable--hidden";
    animatableStartPositionYTopClass = "animatable--start-y-top";
    animatableStartPositionYBottomClass = "animatable--start-y-bottom";
    animatableEndPositionYClass = "animatable--end-y";
    animatableAbsoluteStartPosititionYTopClass =
        "animatable--start-y-top-absolute-centered";
    animatableAbsoluteEndPosititionYTopClass =
        "animatable--end-y-absolute-centered";
    menuDarkClass = "header__menu-btn--dark";
    menuLightClass = "header__menu-btn--light";
    elBgColorClass = "js-bg-color-el";
    menuBtnGridOpenClass = "header__menu-btn--open-grid";
    noScrollClass = "noScroll";
    menuNavItems = $(".js-menu-list li");
    regionsMenuNavItems = $(".js-regions-menu-list li");
    body = $("body");
    window = $(window);
    general = new General();
    introSwitcherEl = $(".js-intro-switcher");
    logo = $(".js-logo");
    headerButton = $(".js-header-button");
    soundSwitchElOpenClass = "header__sound-btn--on";
    shouldHeaderButtonShowAgain = false;
    defaultColor = "rgb(0,0,0)";

    init = () => {
        this.openNavigation();
        this.dynamicMenuBtnColor();
        this.headerElementSwitcher();
    };

    showElement = (el, direction) => {
        el.removeClass(this.animatableHiddenClass);

        if (direction === "up") {
            el.removeClass(this.animatableStartPositionYBottomClass);
        } else {
            el.removeClass(this.animatableStartPositionYTopClass);
        }

        el.addClass(this.animatableVisibleClass).addClass(
            this.animatableEndPositionYClass
        );
    };

    hideElement = (el, direction) => {
        el.removeClass(this.animatableVisibleClass)
            .removeClass(this.animatableEndPositionYClass)
            .addClass(this.animatableHiddenClass);

        if (direction === "up") {
            el.addClass(this.animatableStartPositionYTopClass);
        } else {
            el.addClass(this.animatableStartPositionYBottomClass);
        }
    };

    toggleMenuBtn = (shouldOpen) => {
        if (shouldOpen) {
            this.menuBtn.removeClass(this.menuBtnClosedClass);
            void this.menuBtn.offset();
            this.menuBtn.addClass(this.menuBtnOpenClass);
        } else {
            this.menuBtn.removeClass(this.menuBtnOpenClass);
            void this.menuBtn.offset();
            this.menuBtn.addClass(this.menuBtnClosedClass);
        }
    };

    animateWithDelay = (items, duration, delay, shouldShow) => {
        const totalItems = items.length - 1;
        this.menuBtn.prop("disabled", true);
        this.body.addClass(this.noScrollClass);

        if (shouldShow) {
            if (this.shouldHeaderButtonShowAgain) {
                this.switchLogoAndButton(true);
            }

            items.each((i, item) => {
                const $item = $(item);

                if ($item.css("display") !== "none") {
                    setTimeout(() => {
                        this.showElement($item, "up");

                        if (i === totalItems) {
                            this.menuBtn.prop("disabled", false);
                        }
                    }, duration);

                    duration += delay;
                }
            });
        } else {
            items.each((i, item) => {
                const $item = $(item);

                if ($item.css("display") !== "none") {
                    setTimeout(() => {
                        this.hideElement($item);

                        if (i === totalItems) {
                            this.toggleMenuBtn();
                            this.menu.removeClass(this.menuOpenClass);
                            this.hideElement(this.search, "up");
                            if (this.shouldHeaderButtonShowAgain) {
                                this.switchLogoAndButton();
                            }
                            this.menuBtn.prop("disabled", false);
                            this.body.removeClass(this.noScrollClass);
                        }
                    }, duration);

                    duration += delay;
                }
            });
        }
    };

    animateRegionWithDelay = (items, duration, delay, shouldShow) => {
        const totalItems = items.length - 1;
        this.body.addClass(this.noScrollClass);

        if (shouldShow) {
            items.each((i, item) => {
                const $item = $(item);

                if ($item.css("display") !== "none") {
                    setTimeout(() => {
                        this.showElement($item, "up");
                    }, duration);

                    duration += delay;
                }
            });
        } else {
            items.each((i, item) => {
                const $item = $(item);

                if ($item.css("display") !== "none") {
                    setTimeout(() => {
                        this.hideElement($item);

                        if (i === totalItems) {
                            this.regionsMenu.removeClass(this.menuOpenClass);
                            this.body.removeClass(this.noScrollClass);
                        }
                    }, duration);

                    duration += delay;
                }
            });
        }
    };

    setHeaderButtonShowFlag = () => {
        if (
            this.headerButton.length > 0 &&
            this.headerButton.hasClass(this.animatableVisibleClass)
        ) {
            this.shouldHeaderButtonShowAgain = true;
        } else if (
            this.headerButton.length > 0 &&
            this.headerButton.hasClass(this.animatableHiddenClass)
        ) {
            this.shouldHeaderButtonShowAgain = false;
        }
    };

    openNavigation = () => {
        this.menuBtn.click((e) => {
            const self = $(e.currentTarget);

            if (self.hasClass(this.menuBtnGridOpenClass)) {
                this.toggleMenuBtn();
                this.menuBtn.removeClass(this.menuBtnGridOpenClass);
                this.gridMenu.removeClass(this.menuOpenClass);
                this.body.removeClass(this.noScrollClass);
                this.headerButton
                    .removeClass("animatable--hidden")
                    .addClass("animatable--visible");
            } else {
                if (!self.hasClass(this.menuBtnGridOpenClass)) {
                    if (
                        !self.hasClass(this.menuBtnOpenClass) &&
                        !self.hasClass(this.menuBtnClosedClass)
                    ) {
                        this.setHeaderButtonShowFlag();
                        self.addClass(this.menuBtnOpenClass);
                        this.menu.addClass(this.menuOpenClass);
                        this.showElement(this.search);
                        this.animateWithDelay(this.menuNavItems, 100, 50, true);
                    } else if (self.hasClass(this.menuBtnOpenClass)) {
                        this.animateWithDelay(
                            this.menuNavItems,
                            100,
                            50,
                            false
                        );
                    } else {
                        this.setHeaderButtonShowFlag();
                        this.toggleMenuBtn(true);
                        this.menu.addClass(this.menuOpenClass);
                        this.showElement(this.search);
                        this.animateWithDelay(this.menuNavItems, 100, 50, true);
                    }
                }
            }

            if (this.regionsMenu.hasClass(this.regionsMenuOpenClass)) {
                this.regionsMenu.removeClass(this.regionsMenuOpenClass);
                this.regionsMenuBtn.removeClass("active");
                this.animateRegionWithDelay(
                    this.regionsMenuNavItems,
                    100,
                    50,
                    false
                );
            }
        });

        this.window.on("orientationchange", () => {
            const afterOrientationChange = () => {
                const viewportWidth = this.general.getViewport().width;

                if (
                    viewportWidth > 767 &&
                    this.menu.hasClass("main-menu--open")
                ) {
                    this.animateWithDelay(this.menuNavItems, 100, 50, true);
                }
                this.window.off("resize", afterOrientationChange);
            };
            this.window.on("resize", afterOrientationChange);
        });
    };

    dynamicMenuBtnColor = () => {
        let elementUnderButton = null;
        let elementUnderButtonBgColor = "";
        let menuBtnColor = "";
        let menuBtnPosition = {};

        const setBtnColor = () => {
            if (this.menuBtn.length) {
                menuBtnPosition = this.menuBtn[0].getBoundingClientRect();
                elementUnderButton = document.elementFromPoint(
                    menuBtnPosition.left,
                    menuBtnPosition.top - 1
                );
            }

            if (elementUnderButton) {
                if (
                    !elementUnderButton.classList.contains(this.elBgColorClass)
                ) {
                    elementUnderButton = elementUnderButton.closest(
                        "." + this.elBgColorClass
                    );
                }

                if (elementUnderButton) {
                    elementUnderButtonBgColor =
                        window.getComputedStyle(
                            elementUnderButton
                        ).backgroundColor;
                } else {
                    elementUnderButtonBgColor = this.defaultColor;
                }

                menuBtnColor = this.general.getContrastYIQ(
                    elementUnderButtonBgColor
                );

                if (menuBtnColor === "dark") {
                    this.menuBtn
                        .removeClass(this.menuLightClass)
                        .addClass(this.menuDarkClass);
                } else {
                    this.menuBtn
                        .removeClass(this.menuDarkClass)
                        .addClass(this.menuLightClass);
                }
            }
        };

        setBtnColor();

        /*
        1.) there are multiple resize and scroll events accross the js scripts - these will be unified if there will be time
        2.) scroll event doesn't have a 'throttle' callback since it was unreliable - currently proceeding without throttling
         */
        this.window.on("resize", this.general.throttle(setBtnColor, 50));

        this.window.on("scroll", setBtnColor);
    };

    switchLogoAndButton = (shouldShowLogo) => {
        if (shouldShowLogo) {
            this.headerButton
                .removeClass(this.animatableVisibleClass)
                .addClass(this.animatableHiddenClass)
                .removeClass(this.animatableAbsoluteEndPosititionYTopClass)
                .addClass(this.animatableAbsoluteStartPosititionYTopClass);
            this.showElement(this.logo);
        } else {
            this.hideElement(this.logo);
            this.headerButton
                .removeClass(this.animatableHiddenClass)
                .addClass(this.animatableVisibleClass)
                .removeClass(this.animatableAbsoluteStartPosititionYTopClass)
                .addClass(this.animatableAbsoluteEndPosititionYTopClass);
        }
    };

    headerElementSwitcher = () => {
        if (this.introSwitcherEl.length > 0) {
            const switchElements = () => {
                const isInViewport = this.general.isElementPartiallyInViewport(
                    this.introSwitcherEl
                );
                const isMenuOpen = $("." + this.menuOpenClass).length > 0;

                if (isInViewport && !isMenuOpen) {
                    this.switchLogoAndButton();
                } else if (!this.gridMenu.hasClass(this.menuOpenClass)) {
                    this.switchLogoAndButton(true);
                }
            };

            switchElements();

            /*
             1.) there are multiple resize and scroll events accross the js scripts - these will be unified if there will be time
             2.) scroll event doesn't have a 'throttle' callback since it was unreliable - currently proceeding without throttling
             */
            this.window.on("resize", this.general.throttle(switchElements, 50));

            this.window.on("scroll", switchElements);
        }
    };
}
