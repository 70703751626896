import General from '../general/general';

export default class PhotoCarousel {
    general = new General();
    swiperOptions = {
        resistanceRatio: 0,
        slidesPerView: 4.1,
        spaceBetween: 20,
        breakpoints: {
            1279: {
                slidesPerView: 3.4
            },
            1024: {
                slidesPerView: 2.4
            },
            767: {
                slidesPerView: 1.02
            }
        },
        on: {
            init: () => {
                this.general.setTxtBoxWidth();
            },
            resize: () => {
                this.general.setTxtBoxWidth();
            }
        }
    };

    swiperInit = (el) => {
        this.swiperOptions.navigation = {
            prevEl: el.find('.js-swiper-btn-prev'),
            nextEl: el.find('.js-swiper-btn-next')
        };
        this.general.initSwiper(el, this.swiperOptions)
    }
}
