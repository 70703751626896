import Swiper from "swiper/dist/js/swiper.min";
import "swiper/dist/css/swiper.css";

export default class General {
    window = $(window);
    history = window.history;
    fullVpElement = $(".js-full-viewport");
    imageWithText = $(".js-img-with-txt");
    textForImage = ".js-txt-for-img";
    searchResultsPageEl = $(".js-search-results-page");
    resultsSearchInput = $(".top-intro__search-items input[type='search']");
    headerSearchInput = $(".js-search-element input[type='search']");
    selectEl = $(".top-intro__search-items select");
    selectCalendarFilterElement = $(".js-calendar-country-filter select");

    init() {
        this.setFullHeightElement(this.fullVpElement);
        this.setTxtBoxWidth();
        this.window.on("resize", this.setTxtBoxWidth);
        this.toggleSocialButtons();
        this.handleSearchResultsInput();
        this.handleHeaderSearchInput();
        this.handleSubmit();
        this.handleCalendarFilters();
    }

    /*
     * - get viewport size, code found on https://stackoverflow.com/a/14099073/6696155
     * */
    getViewport = () => {
        let e = window,
            a = "inner";
        if (!("innerWidth" in window)) {
            a = "client";
            e = document.documentElement || document.body;
        }
        return { width: e[a + "Width"], height: e[a + "Height"] };
    };

    /*
     * - function takes color in rgb format as an argument and returns string which is used to determine the background color of foreground element
     * */
    getContrastYIQ = (rgb) => {
        const _rgb = rgb.match(/\d+/g);

        const r = _rgb[0];
        const g = _rgb[1];
        const b = _rgb[2];

        const yiq = (r * 299 + g * 587 + b * 114) / 1000;

        return yiq >= 128 ? "dark" : "light";
    };

    /*
     * - function takes callback function and time limit as arguments
     * */
    throttle = (callback, limit) => {
        let wait = false;
        return () => {
            if (!wait) {
                callback.call();
                wait = true;
                setTimeout(function () {
                    wait = false;
                }, limit);
            }
        };
    };

    /*
     * - function takes element as an argument
     * - it uses getBoundingClientRect method to get the element box and it's values (position from top/left part of the screen)
     *   and it compares these values to the window height/width to determine if the element is visible
     * - it returns 'true' only if element is both horizontally and vertically in view
     * */
    isElementPartiallyInViewport = (el) => {
        const rect = el[0].getBoundingClientRect();

        const windowHeight =
            window.innerHeight || document.documentElement.clientHeight;
        const windowWidth =
            window.innerWidth || document.documentElement.clientWidth;

        const vertInView =
            rect.top <= windowHeight && rect.top + rect.height >= 0;
        const horInView =
            rect.left <= windowWidth && rect.left + rect.width >= 0;

        return vertInView && horInView;
    };

    /*
     * - function takes swiper container element and swiper options as arguments and inits the Swiper with these values
     *   (also returns the Swiper instance if you need to use it somewhere else)
     * */
    initSwiper = (container, options) => {
        return new Swiper(container, options);
    };

    /*
     * - function takes element as a parameter
     * - it sets the element height equal to window height on load, on resize and on orientation change
     * */
    setFullHeightElement = (el) => {
        if (el.length > 0) {
            let cachedWidth = this.getViewport().width;

            const setHeight = () => {
                el.height(window.innerHeight);
            };

            const setHeightOnResize = () => {
                const newWidth = this.getViewport().width;
                if (newWidth !== cachedWidth) {
                    setHeight();
                    cachedWidth = newWidth;
                }
            };

            setHeight();

            this.window.on("orientationchange", () => {
                const afterOrientationChange = () => {
                    setHeight();

                    this.window.off("resize", afterOrientationChange);
                };
                this.window.on("resize", afterOrientationChange);
            });

            this.window.on("resize", this.throttle(setHeightOnResize, 100));
        }
    };

    /*
     * function takes element as a parameter and returns its height (including the paddings)
     * */
    getElementHeight = (el) => {
        return el.outerHeight();
    };

    /*
     * function takes element (anchor with href attribute which has an id of an element we want to scroll to)
     * and aptional additionalOffset parameter which represents the additional offset in pixels
     * */
    smoothScroll = (element, additionalOffset) => {
        const elemAttr = element.attr("href");
        const target = $(elemAttr);
        const addOffset = additionalOffset || 0;

        if (target.length) {
            $("html, body")
                .stop()
                .animate(
                    {
                        scrollTop: target.offset().top - addOffset,
                    },
                    300
                );
        }
    };

    setTxtBoxWidth = () => {
        let self = this;
        if ($(self.textForImage).length > 0) {
            this.imageWithText.each(function () {
                let next =
                    $(this).next(self.textForImage).length !== 0
                        ? $(this).next(self.textForImage)
                        : $(this).next().find(self.textForImage);

                if ($(this).width() > 380) {
                    next.css({ width: "380px" });
                } else {
                    next.css({ width: "260px" });
                }
            });
        }
    };

    debounce = (fun, mil) => {
        let timer;
        return () => {
            clearTimeout(timer);
            timer = setTimeout(() => {
                fun();
            }, mil);
        };
    };

    toggleSocialButtons = () => {
        const shareBtn = $(".js-share-button");
        const socialIcons = $(".js-social-icons");

        shareBtn.on("click", (e) => {
            e.preventDefault();

            if (socialIcons.hasClass("animatable--hidden")) {
                socialIcons
                    .removeClass(
                        "animatable--hidden animatable--start-y-bottom"
                    )
                    .addClass("animatable--visible animatable--end-y");
            } else {
                socialIcons
                    .removeClass("animatable--visible animatable--end-y")
                    .addClass("animatable--hidden animatable--start-y-bottom");
            }
        });
    };

    startSearch = () => {
        const searchPart = "/search/index";
        const origin = window.location.origin;
        const queryString = window.location.search;

        window.location.href = origin + searchPart + queryString;
    };

    pushQueryString = (searchTerm, selectedValue) => {
        const queryPart = "?query=";
        const delimiter = "&";
        const filterPart = "filter=";
        const _selectedValue = selectedValue || 0;
        const _searchTerm = searchTerm || "";

        this.history.pushState(
            null,
            null,
            queryPart + _searchTerm + delimiter + filterPart + _selectedValue
        );
    };

    handleSearchResultsInput = () => {
        if (this.searchResultsPageEl.length > 0) {
            const searchTermEl = $(".js-search-term");
            let searchTerm = searchTermEl.text();
            let selectedValue = this.selectEl.val();
            this.resultsSearchInput.val(searchTerm);
            this.headerSearchInput.val(searchTerm);

            this.pushQueryString(searchTerm, selectedValue);

            this.resultsSearchInput.on("keyup", (e) => {
                searchTerm = this.resultsSearchInput.val();
                this.headerSearchInput.val(searchTerm);

                if (e.key === "Enter") {
                    this.startSearch();
                } else {
                    this.pushQueryString(searchTerm, selectedValue);
                }
            });

            this.selectEl.on("change", () => {
                selectedValue = this.selectEl.val();

                this.pushQueryString(searchTerm, selectedValue);
                this.startSearch();
            });
        }
    };

    handleHeaderSearchInput = () => {
        let selectedValue = 0;

        this.headerSearchInput.on("keyup", (e) => {
            const searchTerm = this.headerSearchInput.val();

            if (this.resultsSearchInput.length > 0) {
                this.resultsSearchInput.val(searchTerm);
            }

            if (e.key === "Enter") {
                this.startSearch();
            } else {
                this.pushQueryString(searchTerm, selectedValue);
            }
        });
    };

    handleSubmit = () => {
        const searchButtons = $(".header__search-btn");

        searchButtons.on("click", () => {
            this.startSearch();
        });
    };

    toggleSwipersOnMobile = (swiper, swiperElements, bp) => {
        let tempSwipers = [];

        const createAndDestroySwipers = (breakpoint) => {
            if (breakpoint.matches) {
                tempSwipers.forEach((swiperInstance) => {
                    swiperInstance.destroy();
                });

                tempSwipers = [];
            } else {
                swiperElements.each((i, el) => {
                    const $el = $(el);

                    const item = swiper.swiperInit($el);
                    tempSwipers.push(item);
                });
            }
        };

        const breakpoint = window.matchMedia("(min-width:" + bp + "px)");
        createAndDestroySwipers(breakpoint);
        breakpoint.addListener(createAndDestroySwipers);
    };

    handleCalendarFilters = () => {
        let selectedValue = this.selectCalendarFilterElement.val();

        this.selectCalendarFilterElement.on("change", () => {
            selectedValue = this.selectCalendarFilterElement.val();

            this.history.pushState(null, null, "?filter=" + selectedValue);

            const searchPart = "/calendar";
            const origin = window.location.origin;
            const queryString = window.location.search;

            window.location.href = origin + searchPart + queryString;
        });
    };
}
