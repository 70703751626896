import General from '../general/general';

export default class CarouselWidgetSwiper {
    general = new General();
    swiperOptions = {
        resistanceRatio: 0,
        slidesPerView: 3,
        spaceBetween: 20,
        breakpoints: {
            767: {
                slidesPerView: 1.2
            }
        }
    };

    swiperInit = (el) => {
        this.swiperOptions.navigation = {
            prevEl: el.find('.js-swiper-btn-prev'),
            nextEl: el.find('.js-swiper-btn-next')
        };
        this.general.initSwiper(el, this.swiperOptions)
    }
}
