export default class Popup {
    init = () => {
        this.closePopup();
    }

    closePopup = () => {
        const popup = document.querySelector('.js-popup-warning');
        const closeBtn = document.querySelector('.js-popup-btn-close');

        closeBtn.addEventListener('click', () => {
            popup.classList.add('hidden')
        })
    }
}