export default class onScreenElements {

    init = () => {
        window.$.expr[":"].onScreen = (elem) => {
            const $window = $(window);
            const viewportTop = $window.scrollTop();
            const viewportHeight = $window.height();
            const viewportBottom = viewportTop + viewportHeight;
            const $elem = $(elem);
            const top = $elem.offset().top;
            const height = $elem.height();
            const bottom = top + height;

            return (top >= viewportTop && top < viewportBottom) ||
                (bottom > viewportTop && bottom <= viewportBottom) ||
                (height > viewportHeight && top <= viewportTop && bottom >= viewportBottom)
        };
    }
}
