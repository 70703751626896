export default class ArchiveCategory {
    select = $('.js-archive-category-list');

    init = () => {
        this.onSelect();
    };

    onSelect() {
        this.select.on('change', function (event) {
            /* double check if it's select */
            let isSelect = event.target.tagName === 'SELECT'
            if (isSelect) {
                let goto = this.value;
                ArchiveCategory.redirect(goto)
            }

        });
    }

    static redirect(goto) {
        if (goto) {
            window.location = goto;
        }
    }
};
