/*
* - class used to init the functions which contain the polyfill
* - naming convention: name of the function is the same as the name of the function which is being polyfilled
* */

import * as es6promisePolyfill from 'es6-promise';

export default class Polyfills {
    init = () => {
        this.closest();
        this.promise();
    };

    closest = () => {
        if (!Element.prototype.matches)
            Element.prototype.matches = Element.prototype.msMatchesSelector ||
                Element.prototype.webkitMatchesSelector;

        if (!Element.prototype.closest) {
            Element.prototype.closest = function(s) {
                let el = this;
                if (!document.documentElement.contains(el)) return null;
                do {
                    if (el.matches(s)) return el;
                    el = el.parentElement || el.parentNode;
                } while (el !== null && el.nodeType === 1);
                return null;
            };
        }
    };

    promise = () => {
        es6promisePolyfill.polyfill();
    }
}
