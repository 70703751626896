import General from '../general/general';

export default class TeaserWidget {
    general = new General();

    init = () => {
        this.general.setTxtBoxWidth();

    };
}
