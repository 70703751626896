export default class BigImageVideo {
    playBtn = $('.js-play-video');
    videoModal = $('.js-video-modal');
    videoUrlEl = $('[data-video-url]');
    videoModalDialog = $('.js-video-modal-dialog');

    init = () => {
        this.createIframeAndStartPlayback();
    };

    /*
    * - if the videoUrlEl exists, get the value from 'data-video-url' attribute
    * - on play button click -> hide the play button -> append the iframe element with video source extracted from 'data-video-url' attribute -> get iframe with jQuery
    * - on modal hide -> show play button -> remove iframe from DOM
    * */
    createIframeAndStartPlayback = () => {
        if (this.videoUrlEl.length > 0) {
            const videoUrl = this.videoUrlEl.attr('data-video-url');
            let videoIframe = null;

            this.playBtn.on('click', () => {
                this.playBtn.hide();
                this.videoModalDialog.append('<iframe class="full-vp-video js-video-iframe" src="' + videoUrl + '?autoplay=1" allow="autoplay" frameborder="0" allowfullscreen></iframe>');
                videoIframe = $('.js-video-iframe');
            });

            this.videoModal.on('hidden.bs.modal', () => {
                this.playBtn.show();

                if (videoIframe.length > 0) {
                    videoIframe.remove();
                }
            });
        }
    };
}
