import General from '../general/general';

export default class BigImageGallerySwiper {
    general = new General();
    swiperOptions = {
        resistanceRatio: 0,
        breakpoints: {
            767: {
                slidesPerView: 1.2,
                spaceBetween: 20
            }
        },
    };

    swiperInit = (el) => {
        this.swiperOptions.navigation = {
            prevEl: el.find('.js-swiper-btn-prev'),
            nextEl: el.find('.js-swiper-btn-next')
        };
        this.general.initSwiper(el, this.swiperOptions)
    };
}
