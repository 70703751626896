import General from '../general/general';

export default class WinnersGridSwiper {
    general = new General();
    swiperOptions = {
        resistanceRatio: 0,
        slidesPerView: 1.2,
        spaceBetween: 20
    };

    swiperInit = (el) => {
        this.general.initSwiper(el, this.swiperOptions)
    }
}
