export default class BackButton {
    backButton = $('.back-button');
    logo = $('.header__logo');
    window = $(window);

    init = () => {
        this.checkBackButton();
        this.handleShowHideLogo();
    };

    handleShowHideLogo = () => {
        const showHideLogo = () => {
            if (this.backButton.length > 0) {
                this.logo.addClass('.js-logo');
                let currentScroll = $(window).scrollTop();
                if (currentScroll > 0) {
                    this.logo.removeClass('animatable--hidden').addClass('animatable--visible');
                    this.backButton.removeClass('animatable--visible').addClass('animatable--hidden');
                } else {
                    this.logo.addClass('animatable--hidden').removeClass('animatable--visible');
                    this.backButton.addClass('animatable--visible').removeClass('animatable--hidden');
                }
            }
        };

        this.window.on('scroll', showHideLogo);

    };

    checkBackButton = () => {
        if (this.backButton.length === 0 && this.logo.next('.button--arrow-left').length === 0) {
            this.logo.addClass('animatable animatable--visible');
        } else {
            this.logo.addClass('animatable animatable--hidden');
        }
    }
}
