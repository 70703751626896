import horizontalScroll from "horizontal-scroll";
import VanillaTilt from "vanilla-tilt";
export default class RegionScroll {
    container = $(".js-hs-item-container");
    items = $(".js-hs-item");
    progressEl = $(".progress-bar");
    scrollContainer = document.querySelector(".js-fake-container");
    hsLinks = document.querySelectorAll(".js-hs-link[href^='#']");

    breakpoint = window.matchMedia("(min-width: 767px)");
    inViewport = true;

    init = () => {
        this.initTilt();

        if (!this.container.length) return;
    };

    setScroll = () => {
        if (this.breakpoint.matches) {
            let hs = new horizontalScroll({
                blocks: this.items,
                container: this.container,
                isAnimated: false,
            });

            const hsWrapper = hs.wrapper;

            this.hsLinks.forEach((link) => {
                link.addEventListener("click", (e) => {
                    e.preventDefault();

                    const hashVal = link.getAttribute("href");
                    const target = hsWrapper.querySelector(hashVal);
                    const targetOffsetLeft = target.offsetLeft - 92; // 92 is region menu
                    const targetOffsetWidth = target.offsetWidth;
                    const windowWidth = window.innerWidth;

                    hs.vars.scrollTarget =
                        targetOffsetLeft -
                        (windowWidth - targetOffsetWidth) / 2;
                });
            });

            const mutationObserver = new MutationObserver(function (mutations) {
                mutations.forEach(function (mutationRecord) {
                    let timeout;

                    if (timeout) {
                        window.cancelAnimationFrame(timeout);
                    }

                    timeout = window.requestAnimationFrame(function () {
                        showReadingProgress();
                    });
                });
            });

            mutationObserver.observe(hsWrapper, {
                attributes: true,
                attributeFilter: ["style"],
            });

            let intersectionObserver = new IntersectionObserver(this.callback);

            intersectionObserver.observe(this.scrollContainer);

            const getScrollProgress = (el) => {
                let progressPercentage = 0;

                if (this.inViewport) {
                    let coords = el.getBoundingClientRect();
                    let width = coords.width;
                    let windowWidth = window.innerWidth;

                    if (coords.left < 0) {
                        let formula =
                            (Math.abs(coords.left) / (windowWidth - width)) *
                            100;
                        progressPercentage = Math.abs(formula);
                    }
                }

                return progressPercentage;
            };

            const showReadingProgress = () => {
                this.progressEl.attr(
                    "value",
                    getScrollProgress(this.scrollContainer)
                );
            };
        }
    };

    callback(entries, observer) {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                this.inViewport = true;
            } else {
                this.inViewport = false;
            }
        });
    }

    getImgWidthAndHeight = () => {
        const container = $(
            ".js-hs-item:not('.all-regions'):not('.region-detail')"
        );
        const images = $(".js-hs-item-img img");
        if (images.length === 0) {
            return;
        }

        const setImgContainerWAndH = () => {
            container.each(function () {
                const breakpoint = window.matchMedia("(min-width: 767px)");

                if (breakpoint.matches) {
                    const container = $(this);
                    const image = $(this).find("img.region-scroll__item-image");
                    if (image.length !== 0) {
                        const width = $(image).prop("naturalWidth");
                        const height = $(image).prop("naturalHeight");

                        container.css("width", `${width}px`);
                        container.css("height", `${height}px`);
                    }
                } else {
                    const container = $(this);

                    container.css("width", "auto");
                    container.css("height", "auto");
                }
            });
        };
        setImgContainerWAndH();

        $(window).on("resize", function () {
            setImgContainerWAndH();
        });
    };

    initTilt() {
        if (this.breakpoint.matches) {
            const itemLink = document.querySelectorAll(".js-tilt");

            if (itemLink.length) {
                VanillaTilt.init(itemLink, {
                    max: 3,
                    speed: 3000,
                });
            }
        }
    }
}
