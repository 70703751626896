import General from '../general/general';
import ArchiveSwiper from '../archive-swiper/archive-swiper';
import axios from 'axios';

export default class ArchivePage {
    body = $('body');
    general = new General();
    archiveSwiper = new ArchiveSwiper();
    yearListItem = $('.js-archive-years-list li');
    archivePage = $('.archive-page');
    archivePageSwiper = $('.archive-page__photo-swiper');
    window = $(window);
    swiperInstances = [];
    select = $('.js-archive-years-list');
    fetchedYears = [];

    init = () => {
        this.onYearClick();
        this.onSelect();
        this.onScroll();
    };

    onScroll = () => {
        this.window.on('scroll', this.general.debounce(() => {
            this.fetchSwiperData();
            this.clearSwipers();
        }, 400));
    };

    onYearClick = () => {
        this.yearListItem.on('click', (e) => {
            this.goToElement($(e.target));
        });
    };

    onSelect() {
        this.select.on('change', (e) => {
            this.goToElement($(e.target));
        });
    }

    getElementsInViewPort = () => {
        return this.archivePageSwiper.filter(':onScreen');
    };

    getSwiperIds = (onScreenElements) => {
        let ids = [];
        onScreenElements.each(function () {
            const id = $(this).attr('data-swiper-id');

            if (id !== null && id !== undefined) {
                ids.push(parseInt(id));
            }
        });

        return ids;
    };

    initSwiper = (swiper) => {
        const mainWrapper = this.archivePage.find("[data-swiper-id='" + swiper.year + "']");
        const swiperElement = $('<div class="swiper-container archive-swiper js-archive-swiper js-bg-color-el"><h2 class="title"><a href="' + swiper.contentPageUrl + '">' + swiper.title + ' '  + swiper.year + '<span class="button button--arrow-right button--arrow-right-title"></span></a></h2><div class="swiper-wrapper"></div><div class="swiper-btns swiper-btns--top-right swiper-btns--horizontal swiper-btns--hidden-mobile"><div href="#" class="swiper-btn swiper-btn--white swiper-btn--round-with-arrow swiper-btn--round-with-arrow-inline swiper-btn--prev js-swiper-btn-prev"></div><div href="#" class="swiper-btn swiper-btn--white swiper-btn--round-with-arrow swiper-btn--round-with-arrow-inline swiper-btn--next js-swiper-btn-next"></div></div></div>');

        swiper.images.forEach(image => {
            const swiperWrapper = swiperElement.find('.swiper-wrapper');
            const swiperSlide = $('<div class="swiper-slide"><div class="archive-swiper__item"><a href="' + image.pageUrl + '"><div class="archive-swiper__item-image img-center js-img-with-txt js-bg-color-el swiper-lazy" title="' + image.title + '" data-background="' + image.imageUrl + '?maxsidesize=400&resizemode=force"><div class="swiper-lazy-preloader"></div></div></a></div></div>');
            swiperWrapper.append(swiperSlide);
        });

        mainWrapper.empty(); //Clean placeholder
        mainWrapper.append(swiperElement);
        const swiperInstance = this.archiveSwiper.swiperInit(swiperElement);
        const swiperData = {
            id: swiper.year,
            instance: swiperInstance
        };
        this.swiperInstances.push(swiperData);
    };

    fetchSwiperData = () => {
        const onScreenElements = this.getElementsInViewPort();
        const swiperIds = this.getSwiperIds(onScreenElements);
        const url = '/api/archive/years';
        const collectionType = $('#js-archive-type').val();
        let data = {};
        let years = [];
        let self = this;

        onScreenElements.each(function () {
            let hasSwiper = $(this).children('.swiper-container');
            if (hasSwiper.length === 0) {
                if (this.dataset.swiperId) {
                    years.push(parseInt(this.dataset.swiperId));
                }
            }
        });

        let goForYears = years.filter(function(e) {
            return self.fetchedYears.indexOf(e) === -1;
        });

        this.fetchedYears = this.fetchedYears.concat(years);
        this.fetchedYears = [...new Set(this.fetchedYears)];

        /**
         * fill the object with values
         */
        data["collectionType"] = collectionType;
        data["years"] = goForYears;

        let headers = {
            'Content-Type': 'application/json'
        };

        if (swiperIds.length > 0 && goForYears.length > 0) {

            const CancelToken = axios.CancelToken;
            let cancel;

            if (Object.keys(data["years"]).length !== 0) {
                axios.post(url, JSON.stringify(data),
                    {
                        headers: headers
                    },
                    {
                        cancelToken: new CancelToken(function executor(c) {
                            // An executor function receives a cancel function as a parameter
                            cancel = c;
                        })
                    })
                    .then(response => {
                        response.data.years.forEach(swiper => {
                            this.initSwiper(swiper);
                        });
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
                cancel();

            } else {
                /* if you need to reinit the swipers use this */
                // onScreenElements.each(function () {
                //     let swiperElem = $(this).children('.swiper-container');
                //     self.archiveSwiper.swiperInit(swiperElem);
                // });
            }
        }
    };

    goToElement = (element, additionalOffset) => {
        let swiperId;
        if (element[0].nodeName !== 'SELECT') {
            swiperId = element.attr('data-target-id');
        } else {
            swiperId = element[0].value;
        }

        let i = 0;
        while (this.archivePage.find("[data-swiper-id='" + swiperId.toString() + "']").length === 0 && i < 10) {
            swiperId++;
            i++; //blocker if there is no year
        }

        const target = this.archivePage.find("[data-swiper-id='" + swiperId.toString() + "']");
        const addOffset = additionalOffset || 0;

        if (target.length) {
            $('html, body').stop().animate({
                scrollTop: target.offset().top - addOffset,
            }, 300).promise().done(() => {
                this.fetchSwiperData()
            });
        }
    };

    clearSwipers = () => {
        // check which swipers are not in the viewport
        let invisibleSwipers = [];
        let self = this;
        this.archivePageSwiper.each(function () {
            if (!self.general.isElementPartiallyInViewport($(this))) {
                invisibleSwipers.push($(this));
            }
        });

        for (let i = 0; i < invisibleSwipers.length; i++) {
            let hasSwiper = invisibleSwipers[i].children('.swiper-container');
            if (hasSwiper.length > 0) {
                // hasSwiper[0].swiper.destroy();
            }
        }
    }
};
