import General from '../general/general';

export default class GridSwiper {
    general = new General();
    pagination = $('.js-story-pagination');
    swiperOptions = {
        resistanceRatio: 0,
        slidesPerView: 3.5,
        spaceBetween: 20,
        breakpoints: {
            1024: {
                slidesPerView: 2.5
            },
            767: {
                slidesPerView: 1.5
            }
        }
    };

    swiperInit = (el) => {
        let initialSlideIndex = 0;

        if (this.pagination.length > 0) {
            initialSlideIndex = parseInt(this.pagination.attr('active-grid-slide')) - 1;
        }

        this.swiperOptions.initialSlide = initialSlideIndex;

        this.swiperOptions.navigation = {
            prevEl: el.find('.js-swiper-btn-prev'),
            nextEl: el.find('.js-swiper-btn-next')
        };
        window.gridSwiperInstance = this.general.initSwiper(el, this.swiperOptions);
    }
}
