import SplitType from "split-type";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);
export default class SplitText {
    splitText = $(".split-text__title");

    init = () => {
        if (!this.splitText.length) return;
        let text;

        const runSplit = () => {
            text = new SplitType(this.splitText, { types: "lines, words" });
            $(".line").append("<div class='line-mask'></div>");
        };

        const runAnimation = () => {
            $(".line").each(function (index) {
                let triggerElement = $(this);
                let targetElement = $(this).find(".line-mask");

                let tl = gsap.timeline({
                    scrollTrigger: {
                        trigger: triggerElement,
                        start: "top center",
                        end: "botoom center",
                        scrub: 1,
                    },
                });

                tl.to(targetElement, {
                    width: "0%",
                    duration: 1,
                });
            });
        };

        runSplit();
        runAnimation();

        window.addEventListener("resize", function () {
            text.revert();
            runSplit();
            runAnimation();
        });
    };
}
