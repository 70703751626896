export default class PhotoDetailsNew {
    pdp = document.querySelector(".photo-details-new");
    fullScreenBtn = document.getElementById("js-full-screen");
    copyToClipboardBtn = document.getElementById("js-copy");

    init = () => {
        if (!this.pdp) return;

        this.fullScreenFn();
        this.copyToClipboardFn();
    };

    fullScreenFn() {
        const globalReqFullScreen = getReqFullScreen();
        this.fullScreenBtn.addEventListener(
            "click",
            function (e) {
                e.preventDefault();

                const target = document.querySelector(".js-can-full-screen");

                if (
                    target.tagName == "IMG" &&
                    target.classList.contains("js-can-full-screen")
                ) {
                    globalReqFullScreen.call(target);
                }
            },
            false
        );

        const globalExitFullScreen = getExitFullScreen();
        document.addEventListener(
            "dblclick",
            function (e) {
                const target = e.target;

                if (
                    target.tagName == "IMG" &&
                    target.classList.contains("js-can-full-screen")
                ) {
                    if (document.fullscreenElement) {
                        globalExitFullScreen.call(document);
                    }
                }
            },
            false
        );

        function getReqFullScreen() {
            const root = document.documentElement;
            return (
                root.requestFullscreen ||
                root.webkitRequestFullscreen ||
                root.mozRequestFullScreen ||
                root.msRequestFullscreen
            );
        }

        function getExitFullScreen() {
            return (
                document.exitFullscreen ||
                document.webkitExitFullscreen ||
                document.mozCancelFullScreen ||
                document.msExitFullscreen
            );
        }
    }

    copyToClipboardFn() {
        this.copyToClipboardBtn.addEventListener("click", (e) => {
            e.preventDefault();

            copyToClipboard();
        });

        function copyToClipboard() {
            const inputToCopy = document.body.appendChild(
                document.createElement("input")
            );

            inputToCopy.value = window.location.href;
            inputToCopy.focus();
            inputToCopy.select();
            document.execCommand("copy");
            inputToCopy.parentNode.removeChild(inputToCopy);

            alert("URL Copied.");
        }
    }
}
